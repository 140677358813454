
<template>
  <div class="container login-warp">
    <div class="title">欢迎回来</div>
    <div class="sub-title">请登录您的账号</div>
    <div class="input-warp mt80">
      <p class="t-l">账号</p>
      <div>
        <input type="text" v-model="phone" />
      </div>
    </div>
    <div class="input-warp mt30">
      <p class="t-l">密码</p>
      <div>
        <input type="password"  v-model="pwd"/>
      </div>
    </div>
    <div class="btn-warp">
      <van-button type="info" color="rgb(108, 143, 248)" @click="handleLogin"
        >登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  computed: {
  },
  data() {
    return {
      phone: "",
      pwd: "",
    };
  },
  mounted() {
  },
  methods: {
    //用于生成uuid
    S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    },
    guid() {
        return (this.S4()+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+this.S4()+this.S4());
    },
    handleLogin() {
       if (!this.phone) {
        Toast("请输入手机号");
        return
      }
      var reg = /^1[3456789]\d{9}$/
      if(!reg.test(this.phone)){
        Toast("请输入正确的手机号");
        return
      }
      if(!this.pwd) {
        Toast("请输入密码");
        return
      }
      this.$store.dispatch("GetInfo",{
        phone: this.phone,
        pwd: this.pwd,
        open_id: this.guid()
      }).then((res) => {
        if (res.code == 0) {
          this.$router.replace({ name: "myhome" });
          this.$store.dispatch("getAreaTree");
          this.$store.commit("setBaseOptions");
        }
      });
    }
  },
};
</script>

<style lang="less">
.login-warp {
  background: #f9f9f9;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    margin-top: 20px;
    color: rgb(51, 65, 84);
    font-size: 32px;
    font-weight: 400;
    line-height: 47px;
  }
  .sub-title {
    color: rgb(97, 110, 124);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 10px;
  }
  .input-warp {
    p {
      font-size: 16px;
      color: rgb(51, 65, 84);
    }
    > div {
      margin-top: 8px;
      height: 54px;
      background: rgb(255, 255, 255);
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        line-height: 100%;
        outline: none;
        border: none;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
}
.mt30 {
  margin-top: 30px;
}
.mt80 {
  margin-top: 60px;
}
.wjmm {
  margin-top: 20px;
}
.btn-warp {
  width: 100%;
  margin-top: 50px;
  .van-button {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    border-radius: 10px;
  }
}
</style>
